<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="!contacts || !contacts.length"
        color="green"
        dark
        class="mb-2 mr-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          small
          class="mr-2"
        >
          mdi-dialpad
        </v-icon>
        Dial Numbers
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Phone number to connect call to</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="field.number"
                label="Number *"
                required
              />
            </v-col>
          </v-row>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="call"
        >
          Call
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'

  export default {
    name: 'DialNumberDialog',
    props: {
      contacts: {
        type: Array,
        default: null,
      },
      group: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      user: null,
      dialog: false,
      groups: [],
      field: {
        number: '',
      },
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    async created () {
      this.usremail = localStorage.getItem('username')
      await this.initialize()
    },

    methods: {
      async initialize () {
      },

      close () {
        this.dialog = false
        this.field = Object.assign({})
      },

      call () {
        if (!Array.isArray(this.contacts) && !this.contacts.length) {
          return
        }

        if (!(this.field.number && this.field.number.length)) {
          return
        }

        const contacts = []
        this.contacts.forEach((contact) => {
          contacts.push(contact.cellNumber)
        })
        const form = {
          ToPhoneNumber: contacts.join(';'),
          ConnectToNumber: this.field.number,
        }
        this.$emit('on-send-start')
        this.httpConnector.makeRequest('post', `${this.apiEndpoint}TwilioClick2Call?code=${this.apiCode}`, form)
          .then((res) => {
            this.$emit('on-success', res)
            this.close()
          })
          .catch((error) => {
            this.$emit('on-error', error)
          })
      },
    },
  }
</script>
